// @file Wrapper for ResizeObserver
import window, { ResizeObserver, ResizeObserverEntry } from '@@/bits/global'
type CallbackFunctionType = (element: HTMLElement, contentRect: DOMRectReadOnly) => void
const canObserveSizeChange = true

/**
 * Observe the visibility of a dom element and call a function when visibile.
 * Returns false if resize observer is not supported by the browser.
 * @param element The dom element to observe the resize of
 * @param callback The method to call
 */
function observeSizeChange(element: HTMLElement, callback: CallbackFunctionType): ResizeObserver | null {
  if (canObserveSizeChange) {
    const resizeObserver = new window.ResizeObserver((entries: ResizeObserverEntry[]): void => {
      entries.forEach((entry: ResizeObserverEntry): void => {
        if (entry.contentRect) {
          callback(element, entry.contentRect)
        }
      })
    })
    resizeObserver.observe(element)
    return resizeObserver
  }
  return null
}

export { canObserveSizeChange, observeSizeChange }

